<template>
  <div class="w-100">
    <div class="global-green flex ac-c center-xs pt-30 pb-10" >
      <div class="flex w-100 jc-c">
        <h2 class="col-xs-12 mt-30 mb-15">Devices</h2>
        <h3 class="col-xs-12 mb-30">Devices from Comcast and other vendors</h3>
        <div class="flex w-90 center-xs center-md list-max-width">
          <list origin="devices" :listItems="data.devices"></list>
        </div>
      </div>
<!--      <div class="button-small-more mt-15 mb-30 pl-10 pr-10">Filter</div>-->
      <div class="button-small-more col-xs-2 col-md-1 mt-15 mb-30 ml-15 pl-10 pr-10" @click="show.showAllVisible = true">Show all</div>
    </div>
    <div class="flex w-100 center-xs mt-30 mb-30">
      <div class="flex col col-xs-8 col-md-5">
        <h2 class="mt-30 mb-15">Compare scores</h2>
        <h3 class="mb-30">Compare devices, browsers and frameworks</h3>
        <div class="button-solid-green-70 flex jc-c ai-c mb-30" @click="compare">
          <span>Compare</span>
        </div>
      </div>
    </div>
    <div class="global-blue flex ac-c center-xs pt-30 pb-10" >
      <div class="flex w-100 jc-c">
        <h2 class="col-xs-12 mt-30 mb-15">My devices</h2>
        <h3 class="col-xs-12 mb-30">Add your own devices to compare, test and run benchmark</h3>
        <div class="flex w-90 center-xs center-md list-max-width">
          <!--ITEMS WILL SHOW AFTER CREATION-->
        </div>
      </div>
      <div class="button-small-add mt-15 mb-30 pl-10 pr-10" @click="navigateToCreate">Add devices</div>
    </div>
  </div>
  <show-all-popup v-if="show.showAllVisible" :data="show.data" about="devices" @close="show.showAllVisible = false"></show-all-popup>
</template>
<script setup>
import {List, ShowAllPopup} from '@/components';
import { reactive, onBeforeMount, inject } from 'vue';
import { get } from "@/lib/api";
import router from "@/lib/router";
import { Notify  } from 'vant';

const data = reactive({
    devices: []
})

const disableNonMVPFeatures = inject('disableNonMVPFeatures')
function navigateToCreate() {
  disableNonMVPFeatures ? showNotify() : router.push({path: '/devices/add'})
}
const maxTilesInList = inject('maxTilesInList')

const show = reactive({
  showAllVisible: false,
  about: '',
  data: []
})

onBeforeMount(async() => {
    try {
        const res = await get('users/me/devices', true)
        show.data = res.data
        data.devices = show.data.length > maxTilesInList ? show.data.slice(0, maxTilesInList) : show.data
    }
    catch(error) {
        console.error("dataFetchError : " + error)
    }
})
function compare(){
  disableNonMVPFeatures ? showNotify() : '' //Handle compare
}

const infoMessage = inject('disabledFeaturesMessage')
function showNotify() {
  Notify ({type: 'warning', message: infoMessage, duration:1500})
}
</script>